<template>
  <div class="my-select" @mouseenter="mouseenter" @mouseleave="showClose = false">
    <el-dropdown
      style="width: 100%;"
      trigger="click"
      @visible-change="visibleChange"
      @command="chooseItem"
    >
      <div class="select-input">
        <div v-if="!presentItem && !filterable" class="default">{{ placeholder }}</div>
        <el-input
          v-if="!presentItem && filterable"
          v-model="search"
          :placeholder="placeholder"
        ></el-input>
        <template v-if="presentItem">
          <div class="present-top">
            <div class="tag">
              <div class="label">公司名</div>
              <div class="label-info">{{ presentItem?.companyName }}</div>
            </div>
            <div v-if="showEin" class="tag">
              <div class="label">EIN</div>
              <div class="label-info">{{ presentItem?.ein }}</div>
            </div>
          </div>
          <div class="present-bottom">
            <p>
              {{ presentItem?.addressLine1 | formatStatusAddress }}
              {{ presentItem?.addressLine2 | formatStatusAddress }}
              {{ presentItem?.city | formatStatusAddress }}
              {{ presentItem?.state | formatStatusAddress }}
              {{ presentItem?.countryCode | formatStatusAddress }}
              <strong>{{ presentItem?.postalCode ? presentItem?.postalCode : '' }}</strong>
            </p>
          </div>
        </template>
        <i
          v-show="!showClose"
          :class="[
            'el-icon-arrow-up',
            'el-icon--right',
            arrow ? 'arrowdown' : '',
          ]"
        ></i>
      </div>
      <el-dropdown-menu ref="dropdownMenu" slot="dropdown" :style="{width: width, maxHeight: '200px', overflow: 'auto'}">
        <template v-if="dropdownItems.length">
          <el-dropdown-item
            v-for="item in dropdownItems"
            :key="item.id"
            :command="item.companyName"
            class="dropdownItems"
          >
            <div class="items">
              <p>
                <span class="code">公司名</span>
                <span style="margin-left: 4px;">{{ item.companyName }}</span>
                <span class="postalCode">邮编</span>
                <span style="margin-left: 4px;">
                  {{ item?.postalCode ? item?.postalCode : '' }}
                </span>
                <span v-if="showEin && item?.taxNumber && !item.supplierId" class="postalCode">EIN</span>
                <span v-if="showEin && item?.taxNumber && !item.supplierId" style="margin-left: 4px;">
                  {{ item?.taxNumber ? item?.taxNumber : '' }}
                </span>
                <span v-if="item.supplierId" class="supplier">{{ item.supplierId ? '后台' : '' }}</span>
              </p>
              <p>
                {{ item?.addressLine1 | formatStatusAddress }}
                {{ item?.addressLine2 | formatStatusAddress }}
                {{ item?.city | formatStatusAddress }}
                {{ item?.state | formatStatusAddress }}
                {{ item?.countryCode | formatStatusAddress }}
              </p>
            </div>
          </el-dropdown-item>
        </template>
        <template v-else>
          <p class="empty">无数据</p>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <i
      v-show="showClose"
      :class="[
        'el-icon-circle-close'
      ]"
      @click="close"
    ></i>
  </div>
</template>

<script>
export default {
  filters: {
    formatStatusAddress(val) {
      return val ? val + ',' : ''
    }
  },
  props: {
    selectItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择Name'
    },
    filterable: {
      type: Boolean,
      default: true
    },
    showEin: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '670px'
    }
  },
  data() {
    return {
      arrow: false,
      presentItem: null,
      active: null,
      showClose: false,
      search: ''
    }
  },
  computed: {
    dropdownItems() {
      let dropdownItems
      const srarchVal = this.search.toLowerCase()
      if (!this.search) {
        dropdownItems = this.selectItems.map((a) => {
          return {
            ...a
          }
        })
      } else {
        dropdownItems = this.selectItems.filter(a => a.companyName.toLowerCase().indexOf(srarchVal) > -1).map((a) => {
          return {
            ...a
          }
        })
      }
      return dropdownItems
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(val) {
        this.search = ''
        if (val) {
          this.presentItem = this.selectItems.find(a => val === a.companyName)
          this.presentItem && this.$emit('input', this.presentItem?.companyName)
        } else {
          this.presentItem = null
          this.$emit('input', '')
        }
      }
    }
  },
  methods: {
    visibleChange(e) {
      this.arrow = e
    },
    chooseItem(e) {
      this.presentItem = this.selectItems.find(a => e === a.companyName)
      this.search = ''
      this.$emit('input', this.presentItem.companyName)
    },
    reset() {
      this.presentItem = undefined
      this.$emit('input', '')
    },
    mouseenter() {
      this.presentItem && (this.showClose = true)
    },
    close() {
      this.presentItem = undefined
      this.$emit('input', '')
    }
  }
}
</script>

<style scoped lang="less">
.my-select {
  position: relative;
  width: 100%;
  .el-dropdown {
    display: block;
  }
  .el-input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    &:deep(input) {
      height: 100%;
      border: 0;
    }
  }
  .select-input {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 65px;
    height: auto;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #EEEEEE;
    cursor: pointer;
    padding: 8px 66px 8px 0;
    box-sizing: border-box;
    .default {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #CDCDCD;
    }
    .present-top {
      display: flex;
      margin-left: 8px;
      flex-wrap: wrap;
      .tag {
        display: flex;
        align-items: center;
      }
      .label {
        background: rgba(53, 186, 131, .1);
        border-radius: 4px 4px 4px 4px;
        padding: 4px 10px;
        box-sizing: border-box;
        color: #35BA83;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35BA83;
        margin-right: 4px;
        line-height: normal;
      }
      .label-info {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: normal;
      }
    }
    .present-bottom {
      margin-left: 8px;
      line-height: normal;
      margin-top: 8px;
      p {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
    .el-icon--right {
      position: absolute;
      right: 16px;
      top: 50%;
      color: #c0c4cc;
      font-size: 14px;
      transition: transform 0.3s;
      transform: translateY(-50%) rotateZ(180deg);
    }
    .el-icon-circle-close {
      z-index: 99999;
    }
    .arrowdown {
      transform: translateY(-50%) rotateZ(0deg);
    }
  }
  .el-icon-circle-close {
    position: absolute;
    right: 16px;
    top: 50%;
    color: #c0c4cc;
    font-size: 14px;
    transition: transform 0.3s;
    transform: translateY(-50%) rotateZ(180deg);
    cursor: pointer;
  }
}
.dropdownItems {
  border-bottom: 1px dashed #DBE4F2;
  .items {
    line-height: 30px;
  }
  .code {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #35BA83;
    line-height: 16px;
    padding: 4px 4px;
    background: rgba(53, 186, 131, .1);
  }
  .postalCode {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #35BA83;
    line-height: 16px;
    margin-left: 16px;
    padding: 4px 4px;
    background: rgba(53, 186, 131, .1);
  }
}
/deep/ .empty {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px;
}
</style>
