export const myMixins = {
    data() {
        return {
        }
    },
    methods: {
        refresh() {
            if (this.initList) {
                this.initList()
            } else {
                if (this.getList) {
                    this.getList()
                }
            }
        }
    }
}
