var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "ISF回执记录", size: 919 },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "view" }, [
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("div", { staticClass: "order" }, [
              _c("div", { staticClass: "order-item" }, [
                _c("div", { staticClass: "item-head" }, [_vm._v("提单号")]),
                _c("div", { staticClass: "item-data" }, [
                  _vm._v(_vm._s(_vm.blNo)),
                ]),
              ]),
              _c("div", { staticClass: "order-item" }, [
                _c("div", { staticClass: "item-head" }, [_vm._v("柜号")]),
                _c("div", { staticClass: "item-data" }, [
                  _vm._v(_vm._s(_vm.containerNumber)),
                ]),
              ]),
            ]),
            _vm.height !== 0
              ? _c("BaseTable", {
                  attrs: {
                    height: _vm.height,
                    isPager: false,
                    tableData: _vm.tableData,
                    loading: _vm.loading,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }