<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in config.isfStatusOptions.filter(a => a.value !== 0 && a.value !== -1)"
          :key="item.value"
          v-permissson="`${item.permissson}`"
          class="tabItem"
          :class="{ active: activeName === $index }"
          @click="handleClick($index, item.permissson)"
        >{{ item.label + `(${tabNumer?.find(a => a.type === +item.value)?.qty || 0})` }}
        </span>
      </div>
      <div class="opt">
        <div v-if="filterList.date" class="checkDate flex_c_c">
          查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
        </div>
        <!-- <div
          v-show="newAdd"
          :v-permissson="newAdd"
          class="btn flex_c_c"
          @click="newadd"
        >
          <svg-icon className="icon" iconClass="add" />新增
        </div> -->
        <div class="import" @click="exportExcel">
          <img src="@/assets/images/bill/import.png" alt="" />导出
        </div>
        <div class="filter">
          <base-filter
            ref="BaseFilter"
            :isActive="isActive"
            :width="504"
            @sendFilter="sendFilter"
            @reset="reset"
          >
            <div class="filter">
              <el-form ref="form" :model="filterList" label-width="104px">
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="filterList.date"
                    style="width: 320px"
                    type="daterange"
                    range-separator="至"
                    align="center"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    unlink-panels
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="客户">
                  <el-select
                    v-model="filterList.companyId"
                    style="width: 320px"
                    filterable
                    placeholder="请选择客户"
                    multiple
                  >
                    <el-option
                      v-for="item in companyDrapdown"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="业务员">
                  <el-select
                    v-model="filterList.saleIds"
                    style="width: 320px"
                    filterable
                    placeholder="请选择"
                    multiple
                  >
                    <el-option
                      v-for="item in userOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="myformitem" label="提单/柜号/AMSBLNuber">
                  <template slot="label"> 提单/柜号/<br />AMSBLNuber </template>
                  <base-muti-input
                    ref="baseMutiInput"
                    v-model="filterList.id"
                    style="width: 320px"
                  ></base-muti-input>
                </el-form-item>
              </el-form>
            </div>
          </base-filter>
        </div>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        ref="BaseTable"
        :height="height"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :tableData="{
          ...tableData,
          fileds,
          columns
        }"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @sortChange="sortChange"
      >
        <template slot="blNo" slot-scope="row">
          <span :class="[row.scope.status === 1 ? '' : 'link']" @click="checkReceipt(row.scope)">
            {{ row.scope.blNo }}
            <svg-icon v-if="row.scope.status !== 1" className="icon" iconClass="link" title="查看回执" />
          </span>
        </template>
        <template slot="importerName" slot-scope="row">
          <div class="address">
            <div style="display: flex;">
              <div class="conpanyName">
                <span>公司名</span>
                <span>{{ row.scope?.importerName }}</span>
              </div>
              <div class="conpanyName">
                <span>EIN</span>
                <span>{{ row.scope?.importerNameAddress?.ein }}</span>
              </div>
            </div>
            <p>
              {{
                row.scope.importerNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.importerNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.importerNameAddress?.state | formatStatusAddress }}
              {{ row.scope.importerNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.importerNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.importerNameAddress?.postalCode
                  ? row.scope.importerNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="manufacturerName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.manufacturerName }}</span>
            </div>
            <p>
              {{
                row.scope.manufacturerNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.manufacturerNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.manufacturerNameAddress?.state | formatStatusAddress }}
              {{ row.scope.manufacturerNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.manufacturerNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.manufacturerNameAddress?.postalCode
                  ? row.scope.manufacturerNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="sellingName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.sellingName }}</span>
            </div>
            <p>
              {{
                row.scope.sellingNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.sellingNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.sellingNameAddress?.state | formatStatusAddress }}
              {{ row.scope.sellingNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.sellingNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.sellingNameAddress?.postalCode
                  ? row.scope.sellingNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="buyerName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.buyerName }}</span>
            </div>
            <p>
              {{
                row.scope.buyerNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.buyerNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.buyerNameAddress?.state | formatStatusAddress }}
              {{ row.scope.buyerNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.buyerNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.buyerNameAddress?.postalCode
                  ? row.scope.buyerNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="shipToPartyName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.shipToPartyName }}</span>
            </div>
            <p>
              {{
                row.scope.shipToPartyNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.shipToPartyNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.shipToPartyNameAddress?.state | formatStatusAddress }}
              {{ row.scope.shipToPartyNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.shipToPartyNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.shipToPartyNameAddress?.postalCode
                  ? row.scope.shipToPartyNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="consolidatorName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.consolidatorName }}</span>
            </div>
            <p>
              {{
                row.scope.consolidatorNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.consolidatorNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.consolidatorNameAddress?.state | formatStatusAddress }}
              {{ row.scope.consolidatorNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.consolidatorNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.consolidatorNameAddress?.postalCode
                  ? row.scope.consolidatorNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="stuffingName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.stuffingName }}</span>
            </div>
            <p>
              {{
                row.scope.stuffingNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.stuffingNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.stuffingNameAddress?.state | formatStatusAddress }}
              {{ row.scope.stuffingNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.stuffingNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.stuffingNameAddress?.postalCode
                  ? row.scope.stuffingNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="consigneeName" slot-scope="row">
          <div class="address">
            <div class="conpanyName">
              <span>公司名</span>
              <span>{{ row.scope?.consigneeName }}</span>
            </div>
            <p>
              {{
                row.scope.consigneeNameAddress?.addressLine1 | formatStatusAddress
              }}
              {{
                row.scope.consigneeNameAddress?.addressLine2 | formatStatusAddress
              }}
              {{ row.scope.consigneeNameAddress?.state | formatStatusAddress }}
              {{ row.scope.consigneeNameAddress?.city | formatStatusAddress }}
              {{
                row.scope.consigneeNameAddress?.countryCode | formatStatusAddress
              }}
              <strong>{{
                row.scope.consigneeNameAddress?.postalCode
                  ? row.scope.consigneeNameAddress?.postalCode
                  : ""
              }}</strong>
            </p>
          </div>
        </template>
        <template slot="classification" slot-scope="row">
          {{ row.scope.classification ? "分单" : "直单" }}
        </template>
        <template slot="onBoardDate" slot-scope="row">
          {{ row.scope.onBoardDate | dateFilete }}
        </template>
        <template slot="requestDate" slot-scope="row">
          {{ row.scope.requestDate | dateFilete }}
        </template>
        <template slot="createdOn" slot-scope="row">
          {{ row.scope.createdOn | dateFilete }}
        </template>
        <template slot="updatedOn" slot-scope="row">
          {{ row.scope.updatedOn | dateFilete }}
        </template>
        <template slot="onFileDate" slot-scope="row">
          {{ row.scope.onFileDate | dateFilete }}
        </template>
        <template slot="acceptedDate" slot-scope="row">
          {{ row.scope.acceptedDate | dateFilete }}
        </template>
        <template slot="opt" slot-scope="row">
          <div v-show="activeName === 0">
            <el-button
              v-permissson="'update_a'"
              type="text"
              size="small"
              @click="fillIsf(row.scope)"
            >修改</el-button
            >
            <el-button
              v-permissson="'sync_a'"
              type="text"
              size="small"
              :disabled="row.scope.isSynchronous"
              @click="synchronousISF(row.scope)"
            >同步</el-button
            >
            <el-button
              v-permissson="'detail_a'"
              type="text"
              size="small"
              @click="isfDetail(row.scope)"
            >详情</el-button
            >
          </div>
          <div v-show="activeName === 1 || activeName === 4">
            <el-button
              v-permissson="activeName === 1 ? 'update_b' : 'update_e'"
              type="text"
              size="small"
              @click="fillIsf(row.scope)"
            >修改</el-button
            >
            <el-button
              v-permissson="activeName === 1 ? 'sync_b' : 'sync_e'"
              type="text"
              size="small"
              @click="synchronousISF(row.scope)"
            >同步</el-button
            >
            <el-button
              v-permissson="activeName === 1 ? 'detail_b' : 'detail_e'"
              type="text"
              size="small"
              @click="isfDetail(row.scope)"
            >详情</el-button
            >
            <!-- <el-button
              v-permissson="
                activeName === 1 ? 'viewReceipt_b' : 'viewReceipt_e'
              "
              type="text"
              size="small"
              @click="checkReceipt(row.scope)"
            >查看回执</el-button
            > -->
            <el-button
              v-permissson="activeName === 1 ? 'cancel_b' : 'cancel_e'"
              type="text"
              size="small"
              @click="cancelIsf(row.scope)"
            >取消</el-button
            >
          </div>
          <div v-show="activeName === 2">
            <el-button
              v-permissson="'update_c'"
              type="text"
              size="small"
              @click="fillIsf(row.scope)"
            >修改</el-button
            >
            <el-button
              v-permissson="'sync_c'"
              type="text"
              size="small"
              @click="synchronousISF(row.scope)"
            >同步</el-button
            >
            <el-button
              v-permissson="'detail_c'"
              type="text"
              size="small"
              @click="isfDetail(row.scope)"
            >详情</el-button
            >
            <!-- <el-button
              v-permissson="'viewReceipt_c'"
              type="text"
              size="small"
              @click="checkReceipt(row.scope)"
            >查看回执</el-button
            > -->
            <el-button
              v-permissson="'cancel_c'"
              type="text"
              size="small"
              @click="cancelIsf(row.scope)"
            >取消</el-button
            >
          </div>
          <div v-show="activeName === 3">
            <el-button
              v-permissson="'update_d'"
              type="text"
              size="small"
              @click="fillIsf(row.scope)"
            >修改</el-button
            >
            <el-button
              v-permissson="'detail_d'"
              type="text"
              size="small"
              @click="isfDetail(row.scope)"
            >详情</el-button
            >
            <el-button
              v-permissson="'viewFile'"
              type="text"
              size="small"
              @click="viewFile(row.scope)"
            >查看文件</el-button>
            <!-- <el-button
              v-permissson="'viewReceipt_d'"
              type="text"
              size="small"
              @click="checkReceipt(row.scope)"
            >查看回执</el-button
            > -->
          </div>
          <div v-show="activeName === 5">
            <el-button
              v-permissson="'detail_f'"
              type="text"
              size="small"
              @click="isfDetail(row.scope)"
            >详情</el-button
            >
            <!-- <el-button
              v-permissson="'viewReceipt_e'"
              type="text"
              size="small"
              @click="checkReceipt(row.scope)"
            >查看回执</el-button
            > -->
          </div>
        </template>
      </BaseTable>
    </div>
    <add-isf-drawer
      :drawer="drawer"
      :companyList="companyDrapdown"
      @close="close"
    ></add-isf-drawer>
    <isf-detail-drawer
      :drawer="drawer2"
      :rowData="rowData"
      @close="close"
    ></isf-detail-drawer>
    <isf-receipt-drawer
      :drawer="drawer3"
      :shipmentFileNumber="shipmentFileNumber"
      :blNo="blNo"
      :row="row"
      :containerNumber="containerNumber"
      @close="close"
    ></isf-receipt-drawer>
    <etid-isf-drawer
      :drawer="drawer4"
      :shipmentFileNumber="shipmentFileNumber"
      :companyList="companyDrapdown"
      :row="row"
      @close="close"
      @ok="ok"
    ></etid-isf-drawer>
  </div>
</template>

<script>
import { permissions, getRecentMonthDate } from '@/utils/common'
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
const BaseTable = () => import('@/components/table/BaseTable')
import AddIsfDrawer from './components/AddIsfDrawer.vue'
import IsfDetailDrawer from './components/IsfDetailDrawer.vue'
import IsfReceiptDrawer from './components/IsfReceiptDrawer.vue'
import EtidIsfDrawer from './components/EtidIsfDrawer.vue'
import { getISFPageList, synchronousISF, cancelISF, isfMainifestSave } from '@/api/bill'
import { getSaleList } from '@/api/system'
import { mapGetters } from 'vuex'
import axios from 'axios'
import config from '@/utils/config'
import { myMixins } from '@/mixins'
import * as XLSX from 'xlsx'
export default {
  name: 'ISF',
  components: {
    BaseFilter,
    BaseMutiInput,
    BaseTable,
    AddIsfDrawer,
    IsfDetailDrawer,
    IsfReceiptDrawer,
    EtidIsfDrawer
  },
  filters: {
    dateFilete: function(date) {
      if (date) {
        return date.slice(0, date.length - 3)
      } else {
        return ''
      }
    },
    formatStatusAddress(val) {
      return val ? val + ',' : ''
    }
  },
  mixins: [myMixins],
  data() {
    return {
      config,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      activeName: 1,
      activeCode: '',
      filterList: {
        date: '',
        companyId: [],
        saleIds: '',
        id: []
      },
      drawer: false,
      drawer2: false,
      drawer3: false,
      drawer4: false,
      loading: false,
      tableData: {
        fileds: [],
        columns: [],
        total: 0
      },
      height: 0,
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      isActive: false,
      shipmentFileNumber: '',
      blNo: '',
      containerNumber: '',
      row: {},
      rowData: {},
      tabNumer: [],
      userOptions: [],
      sortable: {}
    }
  },
  computed: {
    ...mapGetters(['billIsfTabNumber', 'companyDrapdown', 'addressBookOptions']),
    newAdd() {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const presentTab = menu.children.find((a) => a.code === this.activeCode)
      const code = presentTab?.children?.find((a) => a.nameCH === '新增')?.code
      if (code) {
        return code
      } else {
        return ''
      }
    },
    fileds() {
      const fileds = [
          {
            filed: 'companyName',
            label: '客户',
            width: '230',
            fixed: 'left'
          },
          {
            filed: 'blNo',
            label: '提单号',
            width: '230',
            fixed: 'left',
            isCopy: true,
            isCustom: true,
            align: 'left'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '230',
            isCopy: true
          },
          {
            filed: 'classification',
            label: '提单类型',
            width: '160px',
            isCustom: true
          },
          {
            filed: 'amsscac',
            label: 'AMSSCAC',
            width: '160px'
          },
          {
            filed: 'amsblNumber',
            label: 'AMSBLNumber',
            width: '160px'
          },
          {
            filed: 'importerName',
            label: 'Importer',
            width: '480',
            isCustom: true
          },
          {
            filed: 'manufacturerName',
            label: 'Manufacture',
            width: '480',
            isCustom: true
          },
          {
            filed: 'sellingName',
            label: 'Selling',
            width: '480',
            isCustom: true
          },
          {
            filed: 'buyerName',
            label: 'Buyer',
            width: '480',
            isCustom: true
          },
          {
            filed: 'shipToPartyName',
            label: 'Ship To Party',
            width: '480',
            isCustom: true
          },
          {
            filed: 'consolidatorName',
            label: 'Consolidator',
            width: '480',
            isCustom: true
          },
          {
            filed: 'stuffingName',
            label: 'Stuffing',
            width: '480',
            isCustom: true
          },
          {
            filed: 'consigneeName',
            label: 'Consignee',
            width: '480',
            isCustom: true
          },
          {
            filed: 'destinationAgentCode',
            label: '目的港',
            width: '140px'
          },
          {
            filed: 'requestDate',
            label: '发送日期',
            width: '160px',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'onBoardDate',
            label: '装货日期',
            width: '160px',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'createdOn',
            label: '创建日期',
            width: '160px',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '140px'
          },
          {
            filed: 'updatedOn',
            label: '修改日期',
            width: '160px',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: '140px'
          },
          {
            filed: 'shipmentFileNumber',
            label: '序列号',
            width: '150px'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '180',
            isCustom: true,
            fixed: 'right'
          }
        ]
        if (this.activeName === 2) {
          fileds.splice(6, 0, {
            filed: 'acceptedDate',
            label: '接受时间',
            width: '160px',
            isCustom: true,
            isSort: true
          })
        }
        if (this.activeName === 3) {
          fileds.splice(6, 0, {
            filed: 'onFileDate',
            label: 'OnFile时间',
            width: '160px',
            isCustom: true,
            isSort: true
          })
        }
        return fileds
    },
    columns() {
      const arr = []
      this.tableData.columns.forEach(item => {
        const importerNameAddress = this.addressBookOptions.find(a => a.companyName === item.importerName)
        const manufacturerNameAddress = this.addressBookOptions.find(a => a.companyName === item.manufacturerName)
        const sellingNameAddress = this.addressBookOptions.find(a => a.companyName === item.sellingName)
        const buyerNameAddress = this.addressBookOptions.find(a => a.companyName === item.buyerName)
        const shipToPartyNameAddress = this.addressBookOptions.find(a => a.companyName === item.shipToPartyName)
        const consolidatorNameAddress = this.addressBookOptions.find(a => a.companyName === item.consolidatorName)
        const stuffingNameAddress = this.addressBookOptions.find(a => a.companyName === item.stuffingName)
        const consigneeNameAddress = this.addressBookOptions.find(a => a.companyName === item.consigneeName)
        arr.push({
          ...item,
          importerNameAddress,
          manufacturerNameAddress,
          sellingNameAddress,
          buyerNameAddress,
          shipToPartyNameAddress,
          consolidatorNameAddress,
          stuffingNameAddress,
          consigneeNameAddress
        })
      })
      return arr
    }
  },
  created() {
    this.filterList.date = getRecentMonthDate(1)
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
    if (!this.addressBookOptions.length) {
      this.$store.dispatch('getAddressBookOptions', { type: -1 })
    }
    this.getUserList()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeList = ['WFS', 'WJS', 'YJS', 'ONFILE', 'NOTONFILE', 'QX']
      const childrenCode = menu.children.map(a => a.code)
      this.activeName = codeList.findIndex((a) => childrenCode.includes(a))
      this.activeCode = menu.children[0].code
      this.getList(this.pagerConfig)
    }
  },
  methods: {
    handleClick(index, code) {
      this.activeName = index
      this.activeCode = code
      const param = {
        pageIndex: 1,
        pageSize: this.pagerConfig.pageSize
      }
      this.pagerConfig = param
      this.getList(param)
    },
    close() {
      this.drawer = false
      this.drawer2 = false
      this.drawer3 = false
      this.drawer4 = false
    },
    ok() {
      this.drawer4 = false
      this.getList(this.pagerConfig)
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.sortable,
            ...this.formatFilter()
          }
        }
        const res = await getISFPageList(params)
        this.loading = false
        if (res.success) {
          this.tabNumer = res.data.tabNumer
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    formatFilter() {
      return {
        startTime: this.filterList.date ? this.filterList.date[0] : undefined,
        endTime: this.filterList.date ? this.filterList.date[1] : undefined,
        status: this.activeName + 1,
        numbers: this.filterList.id.length > 0 ? this.filterList.id : undefined,
        saleIds: this.filterList.saleIds.length > 0 ? this.filterList.saleIds : undefined,
        companyIds:
          this.filterList.companyId.length > 0
            ? this.filterList.companyId
            : undefined
      }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    sendFilter() {
      if (
        this.filterList.date ||
        this.filterList.companyId.length > 0 ||
        this.filterList.id.length > 0 ||
        this.filterList.saleIds.length > 0
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.initList()
    },
    reset() {
      this.$refs.baseMutiInput.reset()
      this.filterList = {
        date: getRecentMonthDate(1),
        companyId: [],
        saleIds: '',
        id: []
      }
      this.isActive = false
    },
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    isfDetail(row) {
      this.drawer2 = true
      this.rowData = row
      this.shipmentFileNumber = row.shipmentFileNumber
    },
    checkReceipt(row) {
      if (row.status === 1) return
      this.shipmentFileNumber = row.shipmentFileNumber
      this.containerNumber = row.containerNumber
      this.blNo = row.blNo
      this.row = row
      this.drawer3 = true
    },
    newadd() {
      this.$store.commit('SET_ADDRESS_BOOK', [])
      this.drawer = true
    },
    async fillIsf(row) {
      // await this.$store.dispatch('getAddressBookOptions', {
      //   type: -1,
      //   companyId: row.companyId
      // })
      this.row = row
      this.shipmentFileNumber = row.shipmentFileNumber
      this.drawer4 = true
    },
    handlePageChange(pager) {
      this.pagerConfig = pager
      this.getList(pager)
    },
    sortChange(orderObj) {
      this.sortable = {
        sortField: orderObj.prop,
        sortBy: orderObj.order === 'asc' ? 0 : 1
      }
      this.getList(this.pagerConfig)
    },
    async synchronousISF(row) {
      const params = []
      const arr = [
        row.buyerNameAddress,
        row.consigneeNameAddress,
        row.consolidatorNameAddress,
        row.importerNameAddress,
        row.manufacturerNameAddress,
        row.sellingNameAddress,
        row.shipToPartyNameAddress,
        row.stuffingNameAddress
      ]
      arr.forEach((a, index) => {
        const obj = {
          0: 5, // buyer 5
          1: 3, // buyer 5
          2: 8, // buyer 5
          3: 4, // buyer 5
          4: 7, // buyer 5
          5: 6, // buyer 5
          6: 9, // buyer 5
          7: 10 // buyer 5
        }
        // a.type = obj[index]
        // a.suCode = a?.code
        // a.addressLine = [a.addressLine1 && a.addressLine1, a.addressLine2 && a.addressLine2]
        params.push({
          ...a,
          type: obj[index],
          addressLine: [a.addressLine1 && a.addressLine1, a.addressLine2 && a.addressLine2]
        })
      })
      try {
        const res = await synchronousISF(row.shipmentFileNumber, params)
        if (res.success) {
          this.$message.success(res.errorMessage)
          this.getList(this.pagerConfig)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async cancelIsf(row) {
      this.$confirm('此操作将取消ISF, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(async() => {
          const params = []
          const arr = [
            row.buyerNameAddress,
            row.consigneeNameAddress,
            row.consolidatorNameAddress,
            row.importerNameAddress,
            row.manufacturerNameAddress,
            row.sellingNameAddress,
            row.shipToPartyNameAddress,
            row.stuffingNameAddress
          ]
          arr.forEach((a, index) => {
            const obj = {
              0: 5, // buyer 5
              1: 3, // buyer 5
              2: 8, // buyer 5
              3: 4, // buyer 5
              4: 7, // buyer 5
              5: 6, // buyer 5
              6: 9, // buyer 5
              7: 10 // buyer 5
            }
            // a.type = obj[index]
            // a.suCode = a?.code
            // a.addressLine = [a.addressLine1 && a.addressLine1, a.addressLine2 && a.addressLine2]
            params.push({
              ...a,
              type: obj[index],
              addressLine: [a.addressLine1 && a.addressLine1, a.addressLine2 && a.addressLine2]
            })
          })
          try {
            const res = await cancelISF(row.shipmentFileNumber, params)
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.getList(this.pagerConfig)
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        })
        .catch(() => {})
    },
    async viewFile(row) {
      const address = []
      const arr = [
        row.buyerNameAddress,
        row.consigneeNameAddress,
        row.consolidatorNameAddress,
        row.importerNameAddress,
        row.manufacturerNameAddress,
        row.sellingNameAddress,
        row.shipToPartyNameAddress,
        row.stuffingNameAddress
      ]
      arr.forEach((a, index) => {
        const obj = {
          0: 5, // buyer 5
          1: 3, // buyer 5
          2: 8, // buyer 5
          3: 4, // buyer 5
          4: 7, // buyer 5
          5: 6, // buyer 5
          6: 9, // buyer 5
          7: 10 // buyer 5
        }
        // a.type = obj[index]
        // a.suCode = a?.code
        // a.addressLine = [a.addressLine1 && a.addressLine1, a.addressLine2 && a.addressLine2]
        address.push({
          ...a,
          type: obj[index],
          addressLine: [a.addressLine1 && a.addressLine1, a.addressLine2 && a.addressLine2]
        })
      })
      const params = {
        buyer: address[0],
        consignee: address[1],
        consolidator: address[2],
        manufacturer: address[4],
        importer: address[3],
        selling: address[5],
        stuffing: address[7],
        shipToParty: address[6],
        shipmentFileNumber: row.shipmentFileNumber
      }
      try {
        const res = await axios({
          url: `/backEnd/api/${this.config.supplyCode}/ISF/isfMainifest`,
          method: 'post',
          responseType: 'blob',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(params)
        })
        const blob = new Blob([res.data], {
          type: 'application/pdf;chartset=UTF-8'
        })
        const pdfUrl = URL.createObjectURL(blob)
        this.$confirm('是否推送ISF文件给客户', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          iconClass: 'warning',
          showClose: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          center: true
        })
          .then(async() => {
            const res = await isfMainifestSave(params)
            if (res.success) {
              this.$message.success(res.errorMessage)
              window.open(pdfUrl, '_blank')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
          .catch(() => {
            window.open(pdfUrl, '_blank')
          })
      } catch (error) {
        console.error(error)
      }
    },
    async exportExcel() {
      const headers = []
      this.$refs.BaseTable.$refs.multipleTable.$children.forEach(col => {
        col.label !== '操作' && headers.push(col.label)
      })
      const fileds = this.fileds.map(a => a.filed)
      try {
        const params = {
          pageIndex: 1,
          pageSize: 5000,
          queryParam: {
            ...this.formatFilter()
          }
        }
        const res = await getISFPageList(params)
        if (res.success) {
          const data = res.data.list.map(a => {
            const address = this.addressBookOptions.find(b => b.companyName === a.importerName)
            const res = {}
            fileds.forEach(b => {
              res[b] = a[b]
              const filters = [
                [(name) => name === 'classification', () => (res[b] = a[b] ? '分单' : '直单')],
                [(name) => name === 'importerName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'manufacturerName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'sellingName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'buyerName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'shipToPartyName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'consolidatorName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'stuffingName', () => (res[b] = this.filterAddress(a.importerName, address))],
                [(name) => name === 'consigneeName', () => (res[b] = this.filterAddress(a.importerName, address))]
              ]
              filters.find(c => c[0](b)) && filters.find(c => c[0](b))[1]()
            })
            return res
          })
          // 生成worksheet
          const ws = XLSX.utils.json_to_sheet(data)
          let colIndex = 0 // 列索引
          headers.forEach(header => {
            // 根据索引获取列字母
            const colLetter = XLSX.utils.encode_col(colIndex)
            // 拼接单元格编号
            const cell = colLetter + '1'
            // 设置单元格值
            ws[cell] = { v: header }
            colIndex++ // 列索引+1
          })
          // 生成workbook
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

          // 导出excel
          XLSX.writeFile(wb, 'ISF表格数据.xlsx')
        }
      } catch (error) {
        console.error(error)
      }
    },
    filterAddress(name, address) {
      const { ein, addressLine1, addressLine2, state, city, countryCode, postalCode } = address
      return `${`公司名：${name}` + `${ein ? `    EIN：${ein}` : ''}` + '\n' +
        this.filterStatusAddress(addressLine1) +
        this.filterStatusAddress(addressLine2) +
        this.filterStatusAddress(state) +
        this.filterStatusAddress(city) +
        this.filterStatusAddress(countryCode) +
        postalCode || ''
      }`
    },
    filterStatusAddress(val) {
      return val ? val + ',' : ''
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .myformitem {
  .el-form-item__label {
    line-height: 20px;
  }
}
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tabItem {
    padding: 20px;
    width: 42px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    cursor: pointer;
  }
  .active {
    color: #2395f3;
  }
  .opt {
    display: flex;
    .import {
      width: 82px;
      height: 30px;
      background: #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .btn {
      width: 80px;
      height: 30px;
      background: #2395f3;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      svg {
        font-size: 20px;
      }
    }
    .filter {
      height: 30px;
      margin-left: 5px;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .link {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    cursor: pointer;
  }
  .address {
    text-align: left;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    .conpanyName {
      display: flex;
      align-items: center;
      & span:first-child {
        display: inline-block;
        width: 48px;
        height: 24px;
        line-height: 24px;
        background: rgba(53, 186, 131, 0.1);
        border-radius: 4px 4px 4px 4px;
        color: #35ba83;
        text-align: center;
      }
      & span:last-child {
        color: #333333;
        margin-left: 5px;
      }
      & + .conpanyName {
        margin-left: 5px;
      }
    }
    p {
      color: #666666;
      word-wrap: break-word;
      word-break: break-all;
      white-space: break-spaces;
    }
  }
}
</style>
