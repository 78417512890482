var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-select",
      on: {
        mouseenter: _vm.mouseenter,
        mouseleave: function ($event) {
          _vm.showClose = false
        },
      },
    },
    [
      _c(
        "el-dropdown",
        {
          staticStyle: { width: "100%" },
          attrs: { trigger: "click" },
          on: { "visible-change": _vm.visibleChange, command: _vm.chooseItem },
        },
        [
          _c(
            "div",
            { staticClass: "select-input" },
            [
              !_vm.presentItem && !_vm.filterable
                ? _c("div", { staticClass: "default" }, [
                    _vm._v(_vm._s(_vm.placeholder)),
                  ])
                : _vm._e(),
              !_vm.presentItem && _vm.filterable
                ? _c("el-input", {
                    attrs: { placeholder: _vm.placeholder },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  })
                : _vm._e(),
              _vm.presentItem
                ? [
                    _c("div", { staticClass: "present-top" }, [
                      _c("div", { staticClass: "tag" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("公司名")]),
                        _c("div", { staticClass: "label-info" }, [
                          _vm._v(_vm._s(_vm.presentItem?.companyName)),
                        ]),
                      ]),
                      _vm.showEin
                        ? _c("div", { staticClass: "tag" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("EIN"),
                            ]),
                            _c("div", { staticClass: "label-info" }, [
                              _vm._v(_vm._s(_vm.presentItem?.ein)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "present-bottom" }, [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatStatusAddress")(
                                _vm.presentItem?.addressLine1
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("formatStatusAddress")(
                                _vm.presentItem?.addressLine2
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("formatStatusAddress")(
                                _vm.presentItem?.city
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("formatStatusAddress")(
                                _vm.presentItem?.state
                              )
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("formatStatusAddress")(
                                _vm.presentItem?.countryCode
                              )
                            ) +
                            " "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.presentItem?.postalCode
                                ? _vm.presentItem?.postalCode
                                : ""
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showClose,
                    expression: "!showClose",
                  },
                ],
                class: [
                  "el-icon-arrow-up",
                  "el-icon--right",
                  _vm.arrow ? "arrowdown" : "",
                ],
              }),
            ],
            2
          ),
          _c(
            "el-dropdown-menu",
            {
              ref: "dropdownMenu",
              style: { width: _vm.width, maxHeight: "200px", overflow: "auto" },
              attrs: { slot: "dropdown" },
              slot: "dropdown",
            },
            [
              _vm.dropdownItems.length
                ? _vm._l(_vm.dropdownItems, function (item) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: item.id,
                        staticClass: "dropdownItems",
                        attrs: { command: item.companyName },
                      },
                      [
                        _c("div", { staticClass: "items" }, [
                          _c("p", [
                            _c("span", { staticClass: "code" }, [
                              _vm._v("公司名"),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "4px" } },
                              [_vm._v(_vm._s(item.companyName))]
                            ),
                            _c("span", { staticClass: "postalCode" }, [
                              _vm._v("邮编"),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "4px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item?.postalCode ? item?.postalCode : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm.showEin && item?.taxNumber && !item.supplierId
                              ? _c("span", { staticClass: "postalCode" }, [
                                  _vm._v("EIN"),
                                ])
                              : _vm._e(),
                            _vm.showEin && item?.taxNumber && !item.supplierId
                              ? _c(
                                  "span",
                                  { staticStyle: { "margin-left": "4px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item?.taxNumber ? item?.taxNumber : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.supplierId
                              ? _c("span", { staticClass: "supplier" }, [
                                  _vm._v(_vm._s(item.supplierId ? "后台" : "")),
                                ])
                              : _vm._e(),
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatStatusAddress")(
                                    item?.addressLine1
                                  )
                                ) +
                                " " +
                                _vm._s(
                                  _vm._f("formatStatusAddress")(
                                    item?.addressLine2
                                  )
                                ) +
                                " " +
                                _vm._s(
                                  _vm._f("formatStatusAddress")(item?.city)
                                ) +
                                " " +
                                _vm._s(
                                  _vm._f("formatStatusAddress")(item?.state)
                                ) +
                                " " +
                                _vm._s(
                                  _vm._f("formatStatusAddress")(
                                    item?.countryCode
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  })
                : [_c("p", { staticClass: "empty" }, [_vm._v("无数据")])],
            ],
            2
          ),
        ],
        1
      ),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showClose,
            expression: "showClose",
          },
        ],
        class: ["el-icon-circle-close"],
        on: { click: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }