var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "填报ISF", size: 720 },
      on: { close: _vm.cancel },
    },
    [
      _c("div", { staticClass: "view" }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "data",
                attrs: { model: _vm.form, rules: _vm.rules },
              },
              [
                _c(
                  "div",
                  { staticClass: "from-container" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "OnBoard Date(抵港日期)",
                                  prop: "onBoardDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "320px" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "请选择时间",
                                  },
                                  model: {
                                    value: _vm.form.onBoardDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "onBoardDate", $$v)
                                    },
                                    expression: "form.onBoardDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Bill Number Type(提单类型)",
                                  prop: "classification",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择提单分类" },
                                    model: {
                                      value: _vm.form.classification,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "classification",
                                          $$v
                                        )
                                      },
                                      expression: "form.classification",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "直单", value: "0" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "分单", value: "1" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "AMS SCAC(AMS代码)",
                                  prop: "amsscac",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.form.amsscac,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "amsscac", $$v)
                                    },
                                    expression: "form.amsscac",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "AMS No.(AMS号码)",
                                  prop: "amsblNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "320px" },
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.form.amsblNumber,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "amsblNumber", $$v)
                                    },
                                    expression: "form.amsblNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Importer Bond Type(磅的类型)",
                                  prop: "bondType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: {
                                      placeholder: "请选择ISF Bond类型",
                                    },
                                    model: {
                                      value: _vm.form.bondType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "bondType", $$v)
                                      },
                                      expression: "form.bondType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "年磅", value: "0" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "买磅", value: "1" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户", prop: "companyId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择客户",
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.form.companyId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "companyId", $$v)
                                      },
                                      expression: "form.companyId",
                                    },
                                  },
                                  _vm._l(_vm.companyList, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "consigneeName" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "input",
                                staticStyle: { "margin-top": "8px" },
                              },
                              [
                                _c("div", { staticClass: "lable" }, [
                                  _c("span", [
                                    _c("span", { staticClass: "require" }, [
                                      _vm._v("* "),
                                    ]),
                                    _vm._v("Consignee(收货人)"),
                                  ]),
                                ]),
                              ]
                            ),
                            _c("custom-select", {
                              ref: "customSelect1",
                              attrs: {
                                value: _vm.form.consigneeName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.consigneeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "consigneeName", $$v)
                                },
                                expression: "form.consigneeName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "importerName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Importer(进口商)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect2",
                              attrs: {
                                value: _vm.form.importerName,
                                selectItems: _vm.scinOptions,
                                showEin: true,
                              },
                              model: {
                                value: _vm.form.importerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "importerName", $$v)
                                },
                                expression: "form.importerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "buyerName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Buyer(买家)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect3",
                              attrs: {
                                value: _vm.form.buyerName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.buyerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "buyerName", $$v)
                                },
                                expression: "form.buyerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "sellingName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Selling Party(卖家)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect4",
                              attrs: {
                                value: _vm.form.sellingName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.sellingName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sellingName", $$v)
                                },
                                expression: "form.sellingName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "shipToPartyName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Ship To Party(通知人)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect5",
                              attrs: {
                                value: _vm.form.shipToPartyName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.shipToPartyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "shipToPartyName", $$v)
                                },
                                expression: "form.shipToPartyName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "manufacturerName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Manufacture(制造商)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect6",
                              attrs: {
                                value: _vm.form.manufacturerName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.manufacturerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "manufacturerName", $$v)
                                },
                                expression: "form.manufacturerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "consolidatorName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Consolidator(拼柜地)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect7",
                              attrs: {
                                value: _vm.form.consolidatorName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.consolidatorName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "consolidatorName", $$v)
                                },
                                expression: "form.consolidatorName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "stuffingName" } },
                          [
                            _c("div", { staticClass: "input" }, [
                              _c("div", { staticClass: "lable" }, [
                                _c("span", [
                                  _c("span", { staticClass: "require" }, [
                                    _vm._v("* "),
                                  ]),
                                  _vm._v("Container Staffing Location(装货地)"),
                                ]),
                              ]),
                            ]),
                            _c("custom-select", {
                              ref: "customSelect8",
                              attrs: {
                                value: _vm.form.stuffingName,
                                selectItems: _vm.scinOptions,
                              },
                              model: {
                                value: _vm.form.stuffingName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "stuffingName", $$v)
                                },
                                expression: "form.stuffingName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "tabel" }, [
                  _c("div", { staticClass: "tabel-label" }, [
                    _vm._v("+HS CODE列表"),
                  ]),
                  _c("div", { staticClass: "packageList" }, [
                    _c("ul", [
                      _c("li", { staticClass: "header" }, [
                        _c("div", { staticClass: "tabel-data" }, [
                          _c("div", { staticClass: "grid flex_c_c" }, [
                            _c("span", { staticClass: "red" }, [_vm._v("*")]),
                            _vm._v(" 海关编码"),
                          ]),
                          _c("div", { staticClass: "grid flex_c_c" }, [
                            _c("span", { staticClass: "red" }, [_vm._v("*")]),
                            _vm._v(" 原产地国家二字代码"),
                          ]),
                          _c("div", { staticClass: "grid flex_c_c" }, [
                            _c("span", { staticClass: "red" }, [_vm._v("*")]),
                            _vm._v(" 英文描述"),
                          ]),
                          _c("div", { staticClass: "grid flex_c_c" }, [
                            _c("i", {
                              staticClass: "el-icon-circle-plus-outline",
                              attrs: { title: "添加" },
                              on: { click: _vm.newadd },
                            }),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "packageList-content" },
                        _vm._l(_vm.form.htsItems, function (item, index) {
                          return _c("li", { key: index }, [
                            _c("div", { staticClass: "tabel-data" }, [
                              _c(
                                "div",
                                { staticClass: "grid flex_c_c" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: 10,
                                      oninput:
                                        "value=value.replace(/^\\.+|[^\\d]/g,'')",
                                    },
                                    model: {
                                      value: item.hsCode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "hsCode", $$v)
                                      },
                                      expression: "item.hsCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "grid flex_c_c" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/[^a-zA-Z.]/g,'').toUpperCase()",
                                      maxlength: 2,
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: item.originCountryCode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "originCountryCode", $$v)
                                      },
                                      expression: "item.originCountryCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "grid flex_c_c" },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: item.nameEN,
                                      callback: function ($$v) {
                                        _vm.$set(item, "nameEN", $$v)
                                      },
                                      expression: "item.nameEN",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "delete grid flex_c_c" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    attrs: { title: "删除" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(index)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              staticClass: "cancel flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.cancel("ruleForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.submit("ruleForm")
                },
              },
            },
            [_vm._v("提交")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }