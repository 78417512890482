<template>
  <Drawer :drawer="drawer" title="ISF回执记录" :size="919" @close="close">
    <div class="view">
      <div class="data">
        <div class="order">
          <div class="order-item">
            <div class="item-head">提单号</div>
            <div class="item-data">{{ blNo }}</div>
          </div>
          <div class="order-item">
            <div class="item-head">柜号</div>
            <div class="item-data">{{ containerNumber }}</div>
          </div>
        </div>
        <BaseTable
          v-if="height !== 0"
          :height="height"
          :isPager="false"
          :tableData="tableData"
          :loading="loading"
        >
        </BaseTable>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable')
// import { getStatusEvents } from '@/api/bill'
import { isfEvents } from '@/api/basic'
export default {
  components: { Drawer, BaseTable },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    shipmentFileNumber: {
      type: String,
      default: ''
    },
    blNo: {
      type: String,
      default: ''
    },
    containerNumber: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      data: {},
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'code',
            label: '代码',
            width: '150px'
          },
          {
            filed: 'message',
            label: '回执信息'
          },
          {
            filed: 'eventDate',
            label: '回执时间',
            width: '180px',
            formatter: (row) =>
              row.eventDate ? row.eventDate.slice(0, row.eventDate.length - 3) : ''
          }
        ],
        columns: [],
        total: 0
      },
      height: 0
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getList()
      }
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getList() {
      try {
        const res = await isfEvents({ AMSSCAC: this.row.amsscac, AMSBLNumber: this.row.amsblNumber })
        // const res = await getStatusEvents({ shipmentFileNumber: this.shipmentFileNumber })
        if (res.success) {
          this.tableData.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  &:deep(.el-row + .el-row) {
    margin-top: 8px;
  }
  .data {
    width: calc(100% - 20px);
    height: 100%;
    padding: 0 0 20px 20px;
    box-sizing: border-box;
    .order {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
    }
    .order-item {
      display: flex;
      align-items: center;
      width: 50%;
      height: auto;
    }
    .item-head {
      width: 160px;
      height: 40px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-right: 20px;
      text-align: center;
      line-height: 40px;
    }
    .item-data {
      flex: 1;
      height: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #666666;
    }
  }
}

/deep/ .el-drawer__body {
  overflow: auto;
}
</style>
