<!-- eslint-disable consistent-this -->
<template>
  <Drawer :drawer="drawer" title="填报ISF" :size="720" @close="cancel">
    <div class="view">
      <div>
        <el-form ref="ruleForm" class="data" :model="form" :rules="rules">
          <div class="from-container">
            <el-row>
              <el-col :span="12">
                <el-form-item label="OnBoard Date" prop="onBoardDate">
                  <el-date-picker
                    v-model="form.onBoardDate"
                    style="width: 320px;"
                    type="date"
                    placeholder="请选择时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Bill Number Type" prop="classification">
                  <el-select
                    v-model="form.classification"
                    style="width: 320px;"
                    placeholder="请选择提单分类"
                  >
                    <el-option label="直单" :value="0"></el-option>
                    <el-option label="分单" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="AMS SCAC" prop="amsscac">
                  <el-input
                    v-model="form.amsscac"
                    :disabled="!form.classification"
                    style="width: 320px;"
                    placeholder="请输入">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="AMS No." prop="amsblNumber">
                  <el-input
                    v-model="form.amsblNumber"
                    :disabled="!form.classification"
                    style="width: 320px;"
                    placeholder="请输入">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Importer Bond Type" prop="bondType">
                  <el-select
                    v-model="form.bondType"
                    style="width: 320px;"
                    placeholder="请选择ISF Bond类型"
                  >
                    <el-option label="年磅" :value="0"></el-option>
                    <el-option label="买磅" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="客户" prop="companyId">
                  <el-select
                    v-model="form.companyId"
                    filterable
                    style="width: 320px;"
                    placeholder="请选择客户"
                    @change="handleChange"
                  >
                    <el-option
                      v-for="item in companyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-form-item prop="consigneeName">
                <div class="input" style="margin-top: 8px;">
                  <div class="lable">
                    <span><span class="require">* </span>Consignee</span>
                  </div>
                </div>
                <custom-select v-model="form.consigneeName" :value="form.consigneeName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="importerName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Importer</span>
                  </div>
                </div>
                <custom-select v-model="form.importerName" :value="form.importerName" :selectItems="scinOptions" :showEin="true"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="buyerName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Buyer</span>
                  </div>
                </div>
                <custom-select v-model="form.buyerName" :value="form.buyerName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="sellingName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Selling Party</span>
                  </div>
                </div>
                <custom-select v-model="form.sellingName" :value="form.sellingName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="shipToPartyName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Ship To Party</span>
                  </div>
                </div>
                <custom-select v-model="form.shipToPartyName" :value="form.shipToPartyName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="manufacturerName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Manufacture</span>
                  </div>
                </div>
                <custom-select v-model="form.manufacturerName" :value="form.manufacturerName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="consolidatorName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Consolidator</span>
                  </div>
                </div>
                <custom-select v-model="form.consolidatorName" :value="form.consolidatorName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="stuffingName">
                <div class="input">
                  <div class="lable">
                    <span><span class="require">* </span>Container Staffing Location</span>
                  </div>
                </div>
                <custom-select v-model="form.stuffingName" :value="form.stuffingName" :selectItems="scinOptions"></custom-select>
              </el-form-item>
            </el-row>
          </div>
          <div class="tabel">
            <div class="tabel-label">+HS CODE列表</div>
            <div class="packageList">
              <ul>
                <li class="header">
                  <div class="tabel-data">
                    <div class="grid flex_c_c"><span class="red">*</span> 海关编码</div>
                    <div class="grid flex_c_c"><span class="red">*</span> 原产地国家二字代码</div>
                    <div class="grid flex_c_c"><span class="red">*</span> 英文描述</div>
                    <div class="grid flex_c_c"><i class="el-icon-circle-plus-outline" title="添加" @click="newadd"></i></div>
                  </div>
                </li>
                <div class="packageList-content">
                  <li v-for="(item, index) in form.htsItems" :key="index">
                    <div class="tabel-data">
                      <div class="grid flex_c_c">
                        <el-input v-model="item.hsCode" placeholder="请输入" :maxlength="10" oninput="value=value.replace(/^\.+|[^\d]/g,'')"></el-input>
                      </div>
                      <div class="grid flex_c_c">
                        <el-input v-model="item.originCountryCode" oninput="value=value.replace(/[^a-zA-Z.]/g,'').toUpperCase()" :maxlength="2" placeholder="请输入"></el-input>
                      </div>
                      <div class="grid flex_c_c">
                        <el-input v-model="item.nameEN" placeholder="请输入"></el-input>
                      </div>
                      <div class="delete grid flex_c_c">
                        <i
                          class="el-icon-delete"
                          title="删除"
                          @click="deleteRow(index)"
                        />
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </el-form>
      </div>
      <div class="footer">
        <div class="cancel flex_c_c" @click="cancel('ruleForm')">取消</div>
        <div class="ok flex_c_c" @click="submit('ruleForm')">提交</div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import { getAddressBookDrapdown } from '@/api/basic'
import CustomSelect from './CustomSelect.vue'
import { create, getDetail } from '@/api/bill'
import { mapGetters } from 'vuex'
export default {
  components: { Drawer, CustomSelect },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    shipmentFileNumber: {
      type: String,
      default: ''
    },
    companyList: {
      type: Array,
      require: true
    },
    row: {
      type: Object,
      require: false
    }
  },
  data() {
    return {
      data: {},
      form: {
        onBoardDate: '',
        classification: 0,
        amsscac: '',
        amsblNumber: '',
        bondType: '',
        companyId: '',
        consigneeName: '',
        importerName: '',
        importerEIN: '',
        buyerName: '',
        sellingName: '',
        shipToPartyName: '',
        manufacturerName: '',
        consolidatorName: '',
        stuffingName: '',
        htsItems: []
      },
      rules: {
        amsscac: [
          { required: true, message: '请输入AMS SCAC', trigger: 'blur' }
        ],
        amsblNumber: [
          { required: true, message: '请输入AMS No.', trigger: 'blur' }
        ],
        onBoardDate: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ],
        bondType: [
          { required: true, message: '请选择ISF Bond类型', trigger: 'change' }
        ],
        companyId: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        classification: [
          { required: true, message: '请选择提单分类', trigger: 'change' }
        ],
        consigneeName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        buyerName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        sellingName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        shipToPartyName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        manufacturerName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        consolidatorName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        stuffingName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ],
        importerEIN: [
          { required: true, message: '请选择进口商税号', trigger: 'change' }
        ],
        importerName: [
          { required: true, message: '请选择Name', trigger: 'change' }
        ]
      },
      addressBookOptionsNew: []
    }
  },
  computed: {
    ...mapGetters([
      'addressBookOptions'
    ]),
    scinOptions() {
      if (this.addressBookOptionsNew.length > 0) {
        return this.addressBookOptionsNew.filter((a) => a.type === 20)
      } else {
        return this.addressBookOptions.filter((a) => a.type === 20)
      }
    }
    // shipperNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 2)
    // },
    // consigneeNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 3)
    // },
    // manufacturerNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 7)
    // },
    // sellingNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 6)
    // },
    // buyerNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 5)
    // },
    // shipToPartyNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 9)
    // },
    // consolidatorNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 8)
    // },
    // stuffingNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 10)
    // },
    // importerNameOptions() {
    //   return this.addressBookOptions.filter((a) => a.type === 4)
    // }
  },
  watch: {
    drawer(val) {
      if (!val) return
      if (this.shipmentFileNumber) {
        this.form['shipmentFileNumber'] = this.shipmentFileNumber
        this.getDetail(this.shipmentFileNumber)
      }
      const formKeys = Object.keys(this.form)
      for (const keys in this.row) {
        formKeys.forEach(item => {
          if (keys === item) {
            this.form[keys] = this.row[keys]
          }
        })
      }
      // this.handleChange()
    },
    immediate: true
  },
  methods: {
    async getDetail(shipmentFileNumber) {
      try {
        const res = await getDetail({ shipmentFileNumber })
        if (res.success) {
          const result = res.data && res.data.htsItems.map(item => {
            item.originCountryCode = item.countryCode
            delete item.countryCode
            return {
              originCountryCode: item.originCountryCode,
              hsCode: item.hsCode,
              nameEN: item.nameEN
            }
          })
          this.form.htsItems = result
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.htsItems.length === 0) {
            this.$message.warning('请添加code列表数据')
            return
          }
          let flag = true
          const htsItemsArr = []
          const htsItemLength = [6, 8, 10]
          this.form.htsItems.forEach(item => {
            if (!(item.hsCode && item.originCountryCode)) {
              flag = false
            }
            if (htsItemLength.indexOf(item.hsCode.length) === -1) {
              flag = false
            }
            htsItemsArr.push(item.hsCode)
          })
          if (!flag) {
            this.$message.warning('HS CODE列表不完整, 海关编码必须6、8或者10位')
            return
          }
          if (htsItemsArr.length !== Array.from(new Set(htsItemsArr)).length) {
            this.$message.warning('HS CODE存在相同的海关编码')
            return
          }
          create({ ...this.form, mblId: this.row.mblId }).then((res) => {
            if (res.success) {
              if (this.form.shipmentFileNumber) {
                this.$message.success(res.errorMessage)
              } else {
                this.$message.success(res.errorMessage)
              }
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    cancel() {
      this.addressBookOptionsNew = []
      this.$emit('close')
    },
    newadd() {
      const htsItems = {
        hsCode: '',
        originCountryCode: 'CN',
        nameEN: ''
      }
      this.form.htsItems.push(htsItems)
    },
    deleteRow(index) {
      this.form.htsItems.splice(index, 1)
    },
    async handleChange(val) {
      try {
        const res = await getAddressBookDrapdown({
          type: -1,
          companyId: val
        })
        if (res.success) {
          this.addressBookOptionsNew = res.data
          if (val) {
            const obj = {
              consigneeName: '',
              importerName: '',
              importerEIN: '',
              buyerName: '',
              sellingName: '',
              shipToPartyName: '',
              manufacturerName: '',
              consolidatorName: '',
              stuffingName: ''
            }
            this.form = {
              ...this.form,
              ...obj
            }
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
      // ({
      //     type, companyId
      //   })
      //     .then((res) => {
      //       if (res.success) {
      //         commit(SET_ADDRESS_BOOK, res.data)
      //       }
      //       resolve(res)
      //     })
      //     .catch((err) => {
      //       return reject(err)
      //     })
      // await this.$store.dispatch('getAddressBookOptions', { type: -1, companyId: val })
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  &:deep(.el-row + .el-row) {
    margin-top: 8px;
  }
  .data {
    width: calc(100% - 20px);
    height: 100%;
    padding: 0 0 120px 20px;
    box-sizing: border-box;
    /deep/ .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .packageList {
    width: 100%;
    height: auto;
    max-height: 120px;
    border: 1px solid #eeeeee;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    ul {
      display: inline-block;
      width: 100%;
      li {
        width: 100%;
        height: 40px;
        display: flex;
        .tabel-data {
          width: 100%;
          display: flex;
          height: 100%;
          border-bottom: 1px solid #eeeeee;
          box-sizing: border-box;
          /deep/ .el-input {
            .el-input__inner {
              border: 0;
              text-align: center;
            }
          }
          .red {
            color: red;
          }
          .grid {
            height: 100%;
            border-right: 1px solid #eeeeee;
            overflow: hidden;
          }
          .grid:nth-child(1) {
            width: 120px;
            box-sizing: border-box;
          }
          .grid:nth-child(2) {
            width: 160px;
            box-sizing: border-box;
          }
          .grid:nth-child(3) {
            width: 330px;
            box-sizing: border-box;
          }
          .grid:nth-child(4) {
            width: 50px;
            color: #2395F3;
            font-size: 20px;
            box-sizing: border-box;
            i {
              cursor: pointer;
            }
          }
          & .grid:last-child {
            border-right: 0px;
          }
          .delete {
            color: #2395f3;
            .el-icon-delete {
              cursor: pointer;
            }
          }
        }
      }
      .header {
        width: 100%;
        height: 40px;
        background: #f7f7f7;
      }
      .packageList-content {
        height: 80px;
        overflow-y: auto;
        li {
          div {
            & .delete:last-child {
              width: calc(50px - 11px);
            }
          }
        }
      }
      .packageList-content::-webkit-scrollbar {
        width: 10px;
        border: 1px solid #eeeeee;
      }
      .packageList-content::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
        border: 2px solid transparent;
        border-radius: 50px;
        background-clip: content-box;
      }
    }
  }
  .tabel {
    width: 100%;
    margin-top: 20px;
    .tabel-label {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-bottom: 5px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.16);
    border-radius: 0px 0px 0px 0px;
    padding-left: 220px;
    box-sizing: border-box;
    .cancel {
      width: 120px;
      height: 40px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .input {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #606266;
    line-height: 40px;
  }
  .require {
    color: #f56c6c;
  }
  .lable {
    width: 100%;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
}
/deep/ .el-form-item {
  margin-bottom: 0;
  .el-form-item__label {
    line-height: 30px;
  }
  .el-form-item__content {
    line-height: 24px;
  }
}
/deep/ .el-drawer__body {
  overflow: auto;
}
</style>
