<template>
  <Drawer :drawer="drawer" title="ISF详情" :size="700" @close="close">
    <div class="view">
      <div class="data">
        <div class="plank">
          <div class="row-item">
            <div class="item-head">OnBoard Date</div>
            <div class="itme-data">{{ data.onBoardDate | dateFilete }}</div>
          </div>
          <div class="row-item">
            <div class="item-head">AMS SCAC</div>
            <div class="itme-data">{{ data.amsscac }}</div>
          </div>
          <div class="row-item">
            <div class="item-head">AMS No.</div>
            <div class="itme-data">{{ data.amsblNumber }}</div>
          </div>
          <div class="row-item">
            <div class="item-head">Importer Bond Type</div>
            <div class="itme-data">{{ data.bondType ? '买磅' : '年磅' }}</div>
          </div>
          <div class="row-item">
            <div class="item-head">Bill Number Type</div>
            <div class="itme-data">{{ data.classification ? '分单' : '直单' }}</div>
          </div>
          <div v-for="(item, $index) in addressRowArr" :key="$index" class="row-item">
            <div class="item-head">{{ item.label1 }}<br>{{ item.nameCH }}</div>
            <div class="address">
              <div v-if="item.label1 !== 'Importer'" class="conpanyName">
                <span>公司名</span>
                <span>{{ data[item.label2] }}</span>
              </div>
              <div v-else style="display: flex;">
                <div class="conpanyName">
                  <span>公司名</span>
                  <span>{{ data[item.label2] }}</span>
                </div>
                <div class="conpanyName">
                  <span>EIN</span>
                  <span>{{ rowData[item.label2 + 'Address']?.ein }}</span>
                </div>
              </div>
              <p>
                {{
                    rowData[item.label2 + 'Address']?.addressLine1 | formatStatusAddress
                }}
                {{
                    rowData[item.label2 + 'Address']?.addressLine2 | formatStatusAddress
                }}
                {{ rowData[item.label2 + 'Address']?.city | formatStatusAddress }}
                {{ rowData[item.label2 + 'Address']?.state | formatStatusAddress }}
                {{
                    rowData[item.label2 + 'Address']?.countryCode | formatStatusAddress
                }}
                <strong>{{
                    rowData[item.label2 + 'Address']?.postalCode
                      ? rowData[item.label2 + 'Address']?.postalCode
                      : ""
                }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="tabelList">
          <div class="tabel-haeder">+HS CODE列表</div>
          <BaseTable
            :height="132"
            :isPager="false"
            :tableData="tableData"
            :loading="loading"
            :border="true"
          >
          </BaseTable>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable')
import { getDetail } from '@/api/bill'
export default {
  components: { Drawer, BaseTable },
  filters: {
    dateFilete: function(date) {
      if (date) {
        const newDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(date)
        return newDate[0]
      } else {
        return ''
      }
    },
    formatStatusAddress(val) {
      return val ? val + ',' : ''
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      data: {},
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'hsCode',
            label: '海关编码',
            width: '120px'
          },
          {
            filed: 'countryCode',
            label: '原产地国家二字代码',
            width: '160px'
          },
          {
            filed: 'nameEN',
            label: '英文描述'
          }
        ],
        columns: [],
        total: 0
      },
      addressRowArr: [
        { label1: 'Consignee', label2: 'consigneeName', nameCH: '收货人' },
        { label1: 'Importer', label2: 'importerName', nameCH: '进口商' },
        { label1: 'Buyer', label2: 'buyerName', nameCH: '买方' },
        { label1: 'Selling Party', label2: 'sellingName', nameCH: '卖方' },
        { label1: 'Ship To Party', label2: 'shipToPartyName', nameCH: '收货公司' },
        { label1: 'Manufacture', label2: 'manufacturerName', nameCH: '制造商' },
        { label1: 'Consolidator', label2: 'consolidatorName', nameCH: '拼箱地' },
        { label1: 'Container Staffing Location', label2: 'stuffingName', nameCH: '装柜地' }
      ]
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getList() {
      try {
        const res = await getDetail({ shipmentFileNumber: this.rowData.shipmentFileNumber })
        if (res.success) {
          this.data = res.data
          this.tableData.columns = res.data.htsItems
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  .data {
    width: calc(100% - 20px);
    padding: 0 0 20px 20px;
    box-sizing: border-box;
    .plank {
      overflow: hidden;
    }
    .row-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      overflow: hidden;
    }
    .item-head {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      min-height: 30px;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-right: 20px;
    }
    .itme-data {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      height: auto;
      min-height: 30px;
    }
    .address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      height: auto;
      min-height: 80px;
      .conpanyName {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        & span:first-child {
          display: inline-block;
          width: 48px;
          height: 24px;
          line-height: 24px;
          background: rgba(53, 186, 131, 0.1);
          border-radius: 4px 4px 4px 4px;
          color: #35ba83;
          text-align: center;
        }
        & span:last-child {
          color: #333333;
          margin-left: 5px;
        }
        & + .conpanyName {
          margin-left: 5px;
        }
      }
      p {
        color: #666666;
        word-wrap: break-word;
        word-break: break-all;
        white-space: break-spaces;
      }
    }
    .tabelList {
      width: 100%;
      height: auto;
      margin-top: 10px;
      border-radius: 4px;
      overflow: hidden;
    }
    .tabel-haeder {
      width: 99.7%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #F7F7F7;
      opacity: 1;
      border: 1px solid #EEEEEE;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-bottom: 0;
    }
  }
}

/deep/ .el-drawer__body {
  overflow: auto;
}
</style>
