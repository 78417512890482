var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "ISF详情", size: 700 },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "view" }, [
        _c("div", { staticClass: "data" }, [
          _c(
            "div",
            { staticClass: "plank" },
            [
              _c("div", { staticClass: "row-item" }, [
                _c("div", { staticClass: "item-head" }, [
                  _vm._v("OnBoard Date"),
                ]),
                _c("div", { staticClass: "itme-data" }, [
                  _vm._v(_vm._s(_vm._f("dateFilete")(_vm.data.onBoardDate))),
                ]),
              ]),
              _c("div", { staticClass: "row-item" }, [
                _c("div", { staticClass: "item-head" }, [_vm._v("AMS SCAC")]),
                _c("div", { staticClass: "itme-data" }, [
                  _vm._v(_vm._s(_vm.data.amsscac)),
                ]),
              ]),
              _c("div", { staticClass: "row-item" }, [
                _c("div", { staticClass: "item-head" }, [_vm._v("AMS No.")]),
                _c("div", { staticClass: "itme-data" }, [
                  _vm._v(_vm._s(_vm.data.amsblNumber)),
                ]),
              ]),
              _c("div", { staticClass: "row-item" }, [
                _c("div", { staticClass: "item-head" }, [
                  _vm._v("Importer Bond Type"),
                ]),
                _c("div", { staticClass: "itme-data" }, [
                  _vm._v(_vm._s(_vm.data.bondType ? "买磅" : "年磅")),
                ]),
              ]),
              _c("div", { staticClass: "row-item" }, [
                _c("div", { staticClass: "item-head" }, [
                  _vm._v("Bill Number Type"),
                ]),
                _c("div", { staticClass: "itme-data" }, [
                  _vm._v(_vm._s(_vm.data.classification ? "分单" : "直单")),
                ]),
              ]),
              _vm._l(_vm.addressRowArr, function (item, $index) {
                return _c("div", { key: $index, staticClass: "row-item" }, [
                  _c("div", { staticClass: "item-head" }, [
                    _vm._v(_vm._s(item.label1)),
                    _c("br"),
                    _vm._v(_vm._s(item.nameCH)),
                  ]),
                  _c("div", { staticClass: "address" }, [
                    item.label1 !== "Importer"
                      ? _c("div", { staticClass: "conpanyName" }, [
                          _c("span", [_vm._v("公司名")]),
                          _c("span", [_vm._v(_vm._s(_vm.data[item.label2]))]),
                        ])
                      : _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "conpanyName" }, [
                            _c("span", [_vm._v("公司名")]),
                            _c("span", [_vm._v(_vm._s(_vm.data[item.label2]))]),
                          ]),
                          _c("div", { staticClass: "conpanyName" }, [
                            _c("span", [_vm._v("EIN")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.rowData[item.label2 + "Address"]?.ein
                                )
                              ),
                            ]),
                          ]),
                        ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatStatusAddress")(
                              _vm.rowData[item.label2 + "Address"]?.addressLine1
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm._f("formatStatusAddress")(
                              _vm.rowData[item.label2 + "Address"]?.addressLine2
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm._f("formatStatusAddress")(
                              _vm.rowData[item.label2 + "Address"]?.city
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm._f("formatStatusAddress")(
                              _vm.rowData[item.label2 + "Address"]?.state
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm._f("formatStatusAddress")(
                              _vm.rowData[item.label2 + "Address"]?.countryCode
                            )
                          ) +
                          " "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.rowData[item.label2 + "Address"]?.postalCode
                              ? _vm.rowData[item.label2 + "Address"]?.postalCode
                              : ""
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "tabelList" },
            [
              _c("div", { staticClass: "tabel-haeder" }, [
                _vm._v("+HS CODE列表"),
              ]),
              _c("BaseTable", {
                attrs: {
                  height: 132,
                  isPager: false,
                  tableData: _vm.tableData,
                  loading: _vm.loading,
                  border: true,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }